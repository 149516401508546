import React from "react";
import styled from "styled-components";
import Card1 from "../../images/bg-simu-1.png";
import Card2 from "../../images/bg-simu-2.png";
import Card3 from "../../images/bg-simu-3.png";

const Picto = styled.div`
  display: block;
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  transition:all 200ms;
  background-size: 80%;
  background-position: 0 0;
  @media screen and (max-width: 450px) {
    background-position: 0 15vh;
  }
  @media screen and (min-width: 451px) and (max-width: 575px) {
    background-position: 0 13vh;
  }
  @media screen and (min-width: 576px) and (max-width: 767px) {
    background-position: 0 8vh;
  }
  @media screen and (min-width: 992px) {
    background-size: 56%;
    background-position: 0 10%;
  }
  &.hidden{
    opacity:0;
  }
`;

const PictoWrapper = styled.div`
  display: block;
  position:absolute;
  height: 100%;
  width: 100%;
`;

const images = [
  {
    img: Card1,
    treshold: 0,
    key: 'img-1',
    index: 0
  },
  {
    img: Card2,
    treshold: 500,
    key: 'img-2',
    index: 1
  },
  {
    img: Card3,
    treshold: 1000,
    key: 'img-3',
    index: 2
  }
];

class SimuImageSwitcher extends React.Component {

  componentDidUpdate(oldProps) {
    const newProps = this.props
    if (oldProps.value !== newProps.value) {
      var i;
      let index = 0;
      for (i = 0; i < images.length; i++) {
        if (newProps.value > images[i].treshold) {
          index = i;
        }
      }
      if (index !== this.state.index) {
        this.setState({ index: index })
      }
    }
  }
  constructor(props) {
    super(props);
    this.state = { index: 0 };
  }

  render() {

    return (
      <PictoWrapper>
        {images.map(image =>
          <Picto key={image.key} className={image.index === this.state.index ? '' : 'hidden'} style={{ backgroundImage: 'url(' + image.img + ')' }} />
        )}
      </PictoWrapper>
    )
  }

};

export default SimuImageSwitcher;