import React from "react";
// import { Link } from "gatsby";
import styled from "styled-components";
import CTAWhite from "./cta/ctawhite";
import SbEditable from "storyblok-react";

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  background: #fff;
  width: 100%;
  @media screen and (max-width: 767px) {
    flex-flow: column;
  }
`;

const MainWrapper = styled(Wrapper)`
  padding-top: 29px;
  @media screen and (max-width: 767px) {
    padding-top: 128px;
  }
`;
const Column = styled.div`
  flex: 0 0 33.33%;
  width: 33.33%;
  @media screen and (max-width: 991px) {
    &:nth-child(3) {
      padding-left: 20px;
    }
  }
  @media screen and (max-width: 767px) {
    flex: 0 0 100%;
    width: 100%;
    text-align: center;
    &:nth-child(3) {
      padding-left: 0;
    }
  }
`;
const Title = styled.h4`
  margin: 5px 0;
  font-size: 22px;
  font-weigth: bold;
  line-height: 1.32;
  color: #64a832;
  @media screen and (max-width: 767px) {
    font-size: 20px;
  }
`;
const Text = styled.p`
  font-size: 16px;
  line-height: 1.19;
  color: #707070;
  a {
    :hover {
      color: #64a832;
    }
  }
`;
const FooterLink = styled.a`
  padding: 0 24px;
  font-size: 16px;

  color: #d0d0d0;
  :hover {
    color: #707070;
  }
`;
const ImgHolder = styled.div`
  position: absolute;
  top: -90px;
  right: -10px;
  transition: transform 0.2s;
  :hover {
    transform: scale(1.05);
  }
  @media screen and (max-width: 767px) {
    top: -50px;
    right: 30px;
  }
  @media screen and (max-width: 575px) {
    position: relative;
    max-width: unset;
    img {
      position: absolute;
      left: 175px !important;
    }
  }
`;
// const Footer = () => (
//   <div className="container">
//     <Link to="/">
//       <ImgHolder>
//         <img src={require("../images/illu-confiance.png")} alt="" />
//       </ImgHolder>
//     </Link>
//     <MainWrapper css={"padding-top: 29px;"}>
//       <Column css={"flex: 0 0 25%; width: 25%;"}>
//         <img src={require("../images/logo-footer.png")} alt="" />
//       </Column>
//       <Column>
//         <Title>Horaires d'ouverture</Title>
//         <Text>
//           Lundi au vendredi de
//           <br /> 8h à 17h
//         </Text>
//       </Column>
//       <Column>
//         <Title>Contact</Title>
//         <Text>
//           Renseignements & rendez-vous <br />
//           <a href="tel:+687467010">(+687) 46 70 10</a>
//         </Text>
//       </Column>
//     </MainWrapper>
//     <Wrapper
//       css={"align-items: center; justify-content: center; padding-bottom: 5px;"}
//     >
//       <FooterLink to="/">Mentions légales</FooterLink>
//       <span css="font-size: 16px; color: #D0D0D0;">|</span>
//       <FooterLink to="/">Politique de confidentialité</FooterLink>
//     </Wrapper>
//   </div>
// );
//
// export default Footer;

//class Footer extends React.Component {
//  componentDidMount() {
//  }
//  constructor(props) {
//    super(props);
//    console.log(props);
//  }
//  render() {
//    // let props = this.props;
//    return (
//      <div className="container">
//        <CTAWhite
//          data-aos="fade-zoom-in"
//          data-aos-easing="ease-out"
//          data-aos-delay="100"
//          data-aos-duration="1000"
//          buttonWidth="471px"
//          css="margin: 30px 0 20px"
//          as="a"
//          target="_blank"
//          href={props.blok.surface_first_btn_file}
//        >
//        <Link to="/">
//          <ImgHolder>
//            <img src={require("../images/illu-confiance.png")} alt="" />
//          </ImgHolder>
//        </Link>
//        <MainWrapper css={"padding-top: 29px;"}>
//          <Column css={"flex: 0 0 25%; width: 25%;"}>
//            <img src={require("../images/logo-footer.png")} alt="" />
//          </Column>
//          <Column>
//            <Title>Horaires d'ouverture</Title>
//            <Text>
//              Lundi au vendredi de
//              <br /> 8h à 17h
//            </Text>
//          </Column>
//          <Column>
//            <Title>Contact</Title>
//            <Text>
//              Renseignements & rendez-vous <br />
//              <a href="tel:+687467010">(+687) 46 70 10</a>
//            </Text>
//          </Column>
//        </MainWrapper>
//        <Wrapper
//          css={"align-items: center; justify-content: center; padding-bottom: 5px;"}
//        >
//          <FooterLink to="/">Mentions légales</FooterLink>
//          <span css="font-size: 16px; color: #D0D0D0;">|</span>
//          <FooterLink to="/">Politique de confidentialité</FooterLink>
//        </Wrapper>
//      </div>
//    );
//  }
//}
const Footer = (props) => (
  <SbEditable content={props.blok}>
    <div className="container">
      <CTAWhite
        data-aos="fade-zoom-in"
        data-aos-easing="ease-out"
        data-aos-delay="100"
        data-aos-duration="1000"
        data-aos-offset="-50"
        buttonWidth="471px"
        css="margin: 30px 0 20px;display:initial;"
        as="a"
        target="_blank"
        href={props.blok.charte_confiance}
      >
        <ImgHolder>
          <img src={require("../images/illu-confiance.png")} alt="" />
        </ImgHolder>
      </CTAWhite>
      <MainWrapper css={"padding-top: 29px;"}>
        <Column css={"flex: 0 0 25%; width: 25%;"}>
          <img src={require("../images/logo-footer.png")} alt="" />
        </Column>
        <Column>
          <Title>Horaires d'ouverture</Title>
          <Text>
            Lundi au vendredi de
            <br /> 8h à 17h
          </Text>
        </Column>
        <Column>
          <Title>Contact</Title>
          <Text>
            Renseignements & rendez-vous <br />
            <a href="tel:+687467010">(+687) 46 70 10</a>
          </Text>
        </Column>
      </MainWrapper>
      <Wrapper
        css={
          "align-items: center; justify-content: center; padding-bottom: 5px;"
        }
      >
        <FooterLink href={props.blok.mentions_legales} target="_blank">
          Mentions légales - Politique de confidentialité
        </FooterLink>
      </Wrapper>
    </div>
  </SbEditable>
);
export default Footer;
