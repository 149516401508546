import React from "react";
import styled from "styled-components";
import CTAGreen from "./cta/ctagreen";
import SbEditable from "storyblok-react";

const Column = styled.div`
  flex: 0 0 33.33%;
  width: 33.33%;
  @media screen and (max-width: 767px) {
    flex: 0 0 100%;
    width: 100%;
  }
`;
const CardBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: url("${props => props.cardImg}");
  background-size: cover;
  background-repeat: no-repeat;
  height: 40vh;
  width: 100%;
  transition: transform .3s;
  :hover {
    transform: scale(1.02);
  }
  @media screen and (max-width: 767px) {
    margin: 0 auto;
    max-width: 305px;
  }
`;
const CardTitle = styled.p`
  margin-bottom: 5px;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.1;
  color: #fff;
`;
const CardSubtitle = styled.p`
  margin-bottom: 20px;
  font-size: 22px;
  line-heigth: 1.23;
  color: #fff;
`;

const Card = props => (
  <SbEditable content={props.blok}>
    <Column>
      <CardBox
        data-aos="flip-up"
        data-aos-duration="800"
        data-aos-easing="ease-out"
        data-aos-delay={props.blok.delay}
        cardImg={props.blok.card_img}>
        <CardTitle>{props.blok.title}</CardTitle>
        <CardSubtitle>{props.blok.subtitle}</CardSubtitle>
        <CTAGreen
          to="simulator"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          buttonwidth="147px"
          css="margin-bottom: 30px;">
          Simuler
        </CTAGreen>
      </CardBox>
    </Column>
  </SbEditable>
);

export default Card;
