import styled from "styled-components";

const CTABlue = styled.button`
  display: block;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 100%;
  max-width: 300px;
  max-width: ${props => props.buttonWidth};
  border: 0;
  border-radius: 45px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  transition: background-color 0.5s ease;
  background: #4299b9;
  cursor: pointer;
  :hover {
    background: #fff;
    color: #4299b9;
  }
`;

export default CTABlue;
