import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;

const PictoWrapper = styled.div`
  flex: 0 0 15%;
  width: 15%;
  text-align: right;
  margin-right: 5px;
  max-width: 48px;
`;
// const LabelWrapper = styled.div`
//   width: 80%;
// `;
const LabelWrapperInner = styled.div`
  width: 100%;
  padding-left:15px;
  *{
    display:block;
  }
`;

class SimuCard extends React.Component {
  componentDidMount() {
  }

  render() {
    let props = this.props;
    return (
      <Wrapper ref={this.props.innerRef} style={{ paddingLeft: 20, paddingRight: 20 }} className={props.className}>
        <PictoWrapper>
          <img alt="" src={props.img} />
        </PictoWrapper>
        <div className="label-container full-width">
          <LabelWrapperInner>
            <span className="full-width">{props.label}</span>
            <strong className="full-width">{props.value} {props.unit}</strong>
          </LabelWrapperInner>
        </div>
      </Wrapper>)
  }

};

export default React.forwardRef((props, ref) => <SimuCard innerRef={ref} {...props} />);