import Page from "./page";
import Grid from "./grid";
import Teaser from "./teaser";
import Feature from "./feature";
import Banner from "./sections/banner";
import Situation_Surface from "./sections/situation";
import Reference from "./sections/reference";
import Simulator from "./sections/simulator";
import Testimonial from "./sections/testimonial";
import Contact from "./sections/contact";
import Slide from "./slide";
import Card from "./card";
import Footer from "./footer";
import ValueCard from "./valuecard";
import ValueSlider from "./valueslider";
import ImageSwitcher from "./imageswitcher";

export default {
  page: Page,
  banner: Banner,
  situation_surface: Situation_Surface,
  reference: Reference,
  card: Card,
  simulator: Simulator,
  testimonial: Testimonial,
  slide: Slide,
  contact: Contact,
  grid: Grid,
  teaser: Teaser,
  feature: Feature,
  footer: Footer,
  valuecard: ValueCard,
  valueslider: ValueSlider,
  imageswitcher: ImageSwitcher
};
