import React from 'react'
import Components from './components.js';
import Layout from "./layout";
import SEO from "./seo";
import styled from "styled-components";
const PageWrapper = styled.div`
  overflow-x:hidden;
`;
export default class Page extends React.Component {
  render() {
    let props = this.props;
    return (
      <Layout>
        <SEO title="Accueil" keywords={[`panda`, `secal`, `Nouméa`]} />
        <PageWrapper>
          {props.blok.body && props.blok.body.map((blok) => React.createElement(Components[blok.component], { key: blok._uid, blok: blok }))}
        </PageWrapper>
      </Layout>
    );
  }
}