import React from "react";
import styled from "styled-components";
import CTAWhite from "./cta/ctawhite";

const CookiesWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: 0 auto;
  max-width: 780px;
  
  @media screen and (max-width: 991px) and (min-width: 768px) {
    padding: 11px 15px;
  }
  @media screen and (max-width: 767px) {
    justify-content: center;
    padding: 11px 34px;
    height: auto;
  }
`;
const Text = styled.p`
  margin-bottom: 0;
  margin-right: auto;
  max-width: 564px;
  font-size: 14px;
  line-height: 1.29;
  color: #fff;
  @media screen and (max-width: 767px) {
    margin: 0 auto 6px;
  }
`;
const Section = styled.section`
  background: #64a832;
  &.hidden-cookies {
    bottom: -${props => props.cookiesHeight}px;
  }
`;

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

class Cookies extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", this.hideCookies);
    window.addEventListener("resize", this.setHeight);
    this.setHeight();
    let onboarding = getCookie('onboarding');
    if(onboarding && this.state.cookies_class==='cookies'){
      this.cookieAccept();
    }
  }
  constructor(props) {
    super(props);
    this.state = {
      cookies_class: "cookies",
      onboarding:false,
    };
  }
  hideCookies = () => {
    this.setState({ cookies_class: "hidden-cookies" });
  }
  cookieAccept = () => {
    // action accept cookies
    setCookie('onboarding', true, 365);
    if(typeof window!== "undefined"){
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'G-F85F1PBKKC');
    }
    this.hideCookies();
  }
  setHeight = () => {
    const cookiesHeight = document.getElementById("cookies").clientHeight;
    this.setState({ cookiesHeight: cookiesHeight });
  }
  render() {
    // let props = this.props;
    return (


        <Section
          css="position: fixed; bottom: 5px; width: 20%; padding: 15px; 
          z-index: 9999;"
          className={this.state.cookies_class}
          id="cookies"
          cookiesHeight={this.state.cookiesHeight}
          data-aos="fade-up"
          data-aos-duration="4000"
          data-aos-delay="0"
          data-aos-anchor-placement="top-top"
        >
          <CookiesWrapper>
            <Text>En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de cookies pour nous permettre de réaliser des statistiques de visites.</Text>
            <CTAWhite
            css="display:none;"
            onClick={this.cookieAccept}
            buttonWidth="145px">J’accepte</CTAWhite>
          </CookiesWrapper>
        </Section>

    );
  }
}

export default Cookies;
