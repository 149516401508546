import styled from "styled-components";

const CTAWhite = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 100%;
  max-width: 300px;
  outline: none;
  text-align: center;
  max-width: ${props => props.buttonWidth};
  border: 2px solid #fff;
  border-radius: 45px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  background: transparent;
  transition: background-color 0.5s ease;
  cursor: pointer;


  :hover {
    color: #64a832;
    background: white;
    svg>path, svg>g>path, svg>g>g>path {
      fill: #64a832;
    }
  }
  @media screen and (max-width: 444px) {
    position: relative;
    font-size: 14px;
    img {
      position: absolute;
      left: 30px;
    }
    span {
      max-width: 200px;
      text-align: left;
    }
  }
  @media screen and (max-width: 390px) {
    img {
      left: 15px;
    }
  }
`;

export default CTAWhite;
