import React from "react";
import styled from "styled-components";
import Section from "../section";
import contact from "../../images/contact.jpg";
import CTAWhite from "../cta/ctawhite";
import Checkbox from "../checkbox";
import SbEditable from "storyblok-react";
import Components from "../components";

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const Anchor = styled.a`
  display: block;
  position: relative;
  visibility: hidden;
  @media screen and (max-width: 991px) {
    top: -70px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  @media screen and (max-width: 767px) {
    min-height: unset;
  }
`;

const Column = styled.div`
  flex: 0 0 50%;
  width: 50%;
  @media screen and (max-width: 767px) {
    flex: 0 0 100%;
    width: 100%;
  }
`;

const FooterBG = styled.div`
  align-self: flex-end;
  background-color: #fff;
  width:100%;
`;

const Title = styled("h2")`
  margin: 20px auto 15px;
  color: #fff;
`;
const Text = styled.p`
  line-height: 1.19;
  color: #fff;
  span {
    font-weight: bold;
  }
`;
const Input = styled.input`
  padding: 18px 20px;
  margin-bottom: 10px;
  height: 55px;
  width: 390px;
  font-size: 16px;
  border: 0;
  border-radius: 4px;
  color: #707070;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background: #fff;
  :placeholder {
    vertical-align: top;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  @media screen and (max-width: 575px) {
    font-size: 11px;
  }
`;

const Textarea = styled(Input)`
  margin-bottom: 0;
  height: 151px;
`;
const Label = styled.label`
  display: inline-flex;
  margin: 16px 0;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  span {
    margin-left: 20px;
    max-width: 348px;
  }
`;
const ContactImg = styled.div`
  display: block;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

class Contact extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      cname: "",
      surface: "",
      email: "",
      tel: "",
      message: "",
      checked: false,
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }



  handleChange = event => {

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = event => {
    const formData = {
      cname: this.state.cname,
      email: this.state.email,
      tel: this.state.tel,
      message: this.state.message,
      checked: this.state.checked,
      surface: this.state.surface
    };
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formData })
    })
      .then(() => this.setState({
        submitted: true
      }))
      .catch(error => alert(error))
    console.log(formData);
    event.preventDefault();
  };

  render() {
    let props = this.props;
    return (
      <SbEditable content={this.props.blok}>
        <Anchor class="anchor" id="contact"></Anchor>
        <Section imageSrc={contact} css={"overflow: hidden;"} data-scroll-class="scroll" data-section-name="contact">


          <Wrapper>
            <div className="container" css="flex-direction: row; display: flex; flex: 1; align-item: center;">
              <Column css="align-self: center;">
                {this.state.submitted ? (
                  <React.Fragment>
                    <Title css="margin: 0 0 10px; max-width: 400px;">
                      Votre message est bien parti.
                      </Title>
                    <Text css="max-width: 400px;">
                      Nous veillerons à y répondre dans les meilleurs délais.
                      Merci.
                      </Text>
                  </React.Fragment>
                ) : (
                    <React.Fragment>
                      <Title data-aos="fade-in"
                        data-aos-easing="ease-in"
                        data-aos-delay="0">
                        {this.props.blok.title}</Title>
                      <Text data-aos="fade-in"
                        data-aos-easing="ease-in"
                        data-aos-delay="50">
                        {this.props.blok.content}</Text>
                      <form onSubmit={this.handleSubmit} name="contact" data-netlify="true">
                        <input type="hidden" name="form-name" value="contact" />
                        <Input
                          data-aos="fade-in"
                          data-aos-easing="ease-in"
                          data-aos-delay="100"
                          data-aos-offset="-50"
                          name="cname"
                          type="text"
                          placeholder="Nom"
                          value={this.state.cname}
                          onChange={this.handleChange}
                          required
                        />
                        <Input
                          name="surface"
                          id="surface-field"
                          type="text"
                          placeholder="surface"
                          value={this.state.surface}
                          onChange={this.handleChange}
                          hidden
                        />
                        <Input
                          data-aos="fade-in"
                          data-aos-easing="ease-in"
                          data-aos-delay="150"
                          data-aos-offset="-50"
                          name="email"
                          type="email"
                          placeholder="Email"
                          value={this.state.email}
                          onChange={this.handleChange}
                          required
                        />
                        <Input
                          data-aos="fade-in"
                          data-aos-easing="ease-in"
                          data-aos-delay="200"
                          data-aos-offset="-50"
                          name="tel"
                          type="tel"
                          pattern="^[0-9-+s()]*$"
                          placeholder="Téléphone"
                          title="Alphabetical letters are not allowed."
                          value={this.state.tel}
                          onChange={this.handleChange}
                        />
                        <Textarea
                          data-aos="fade-in"
                          data-aos-easing="ease-in"
                          data-aos-delay="250"
                          data-aos-offset="-50"
                          name="message"
                          id="message"
                          as="textarea"
                          placeholder="Message"
                          rows="4"
                          value={this.state.message}
                          onChange={this.handleChange}
                          required
                        />
                        <Label
                          data-aos="fade-in"
                          data-aos-easing="ease-in"
                          data-aos-delay="300"
                          data-aos-offset="-50">
                          <Checkbox
                            name="checked"
                            checked={this.state.checked}
                            value={this.state.cheked}
                            onChange={this.handleChange}
                            required
                          />
                          <span>
                            En soumettant ce formulaire, j’accepte que les
                            informations saisies soient exploitées dans le cadre
                            de cette demande de contact et de la relation
                            commerciale qui peut en découler.
                          </span>
                        </Label>
                        <CTAWhite
                          data-aos="fade-in"
                          data-aos-easing="ease-in"
                          data-aos-delay="400"
                          data-aos-offset="-100"
                          buttonWidth="389px" css="margin-bottom: 50px;" type="submit">
                          Envoyer
                        </CTAWhite>
                      </form>
                    </React.Fragment>
                  )}
              </Column>
              <Column data-aos="fade-up" data-aos-offset="-100" css={"margin: auto 0 -4px; align-self: flex-end;"}>
                <ContactImg>
                  <img
                    src={require("../../images/worker-contact.png")}
                    alt=""
                  />
                </ContactImg>
              </Column>
            </div>
            <FooterBG>
              {props.blok.footer.map(blok =>
                React.createElement(Components[blok.component], {
                  key: blok._uid,
                  blok: blok
                })
              )}
            </FooterBG>
          </Wrapper>



        </Section>
      </SbEditable>
    );
  }
}

export default Contact;
