import React from "react";
import SbEditable from "storyblok-react";


class ValueCard extends React.Component {
  componentDidMount() {
  }
  constructor(props) {
    super(props);
    console.log(props);
  }

  render() {
    let props = this.props;
    return (
      <SbEditable content={props.blok}>
        <div>
          <p>{props.blok.label}</p>
          <p>{props.value} {props.blok.unit}</p>
        </div>
      </SbEditable>)
  }

};

export default ValueCard;