import React from "react";
import SbEditable from "storyblok-react";
import Components from './components.js';


class ImageSwitcher extends React.Component {
  componentDidMount() {
  }
  constructor(props) {
    super(props);
    console.log(props);
  }

  render() {
    let props = this.props;
    return (
      <SbEditable content={props.blok}>
        <div>{props.blok.columns.map((blok) =>
          React.createElement(Components[blok.component], { key: blok._uid, blok: blok })
        )}</div>
      </SbEditable>)
  }

};

export default ImageSwitcher;