import React from "react";
import styled from "styled-components";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  @media screen and (max-width: 767px) {
    padding: 60px 0;
    min-height: unset;
  }
`;


class Modal extends React.Component {
  componentDidMount() {
  }
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      visible: props.visible,
      value: 0
    };
  }
  componentDidUpdate(oldProps) {
    const newProps = this.props
    if (oldProps.visible !== newProps.visible) {
      this.setState({ visible: newProps.visible })
    }
  }
  valueChange(value) {
    // this.setState({ value: value })
  }

  render() {
    return (

        <Rodal height='100%' width="100%" visible={this.state.visible} onClose={this.props.action}>
          <div className='container'>
            <Wrapper>
              <img src={require("../images/map-panda.jpg")} alt="" />
            </Wrapper>
          </div>
        </Rodal>)
  }

};

export default Modal;