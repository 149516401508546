import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
// import { Link, animateScroll as scroll } from "react-scroll";
import { Link } from "react-scroll";

const StyledLink = styled(Link)`
  padding: 0 22px;
  text-decoration: none;
  font-size: 18px;
  color: #d4d4d4;
  opacity: 0.8;
  cursor: pointer;
  :hover {
    opacity: 1;
    color: #fff;
  }
  &.active {
    color: #fff !important;
      color: #64a832;
  }
  @media screen and (max-width: 1081px) {
    padding: 20px 0;
    text-align: center;
    font-weight: 500;
    color: #707070;
    opacity: 1;
    &.active,&:hover {
        color: #64a832!important;
    }
  }
`;
const Navbar = styled("header")`
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100px;
  transition: all 1s;
  z-index: 999;
  @media screen and (min-width: 1082px) {
    &.fixed-nav {
      height: 100px;

      ${StyledLink} {
        color: #707070;
        opacity: 1;
        :hover {
          color: #64a832;
        }
      }
    }
  }
  @media screen and (max-width: 1081px) {
    transition: none;
    height: 70px;

    &.fixed-nav {
      position: fixed;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background: #fff;
    }
  }
`;
const NavWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 1080px;
  @media screen and (min-width: 1082px) {
    top: ${props => props.navHidden ? '-' + props.headerHeight + 'px' : 0};
    transition: all 1s;
  }
  @media screen and (max-width: 1081px) and (min-width: 1082px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 1081px) {
    background: #fff;
  }
`;
const Nav = styled("nav")`
  list-style-type: none;
  margin-left: auto;
  padding: 0;
  display: block;
  @media screen and (max-width: 1081px) {
    display: none;
    width: 100%;
  }
`;

const BurgerMenu = styled.button`
  border: 0;
  background: transparent;
  width: 42px;
  height: 28px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  outline: none;
  span {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: #707070;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    &:nth-child(1) {
      top: 0px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
    &:nth-child(2) {
      top: 12px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
    &:nth-child(3) {
      top: 24px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
  }
  &.open {
    span {
      &:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: -3px;
        left: 8px;
      }
      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }
      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 27px;
        left: 8px;
      }
    }
  }
`;

const ScrollToTopLogo = styled(Link)`
  opacity: 1;
  
  @media screen and (min-width: 1082px) {
    align-self: start;
    justify-content: start !important;
    &.active {
      transition: all 1s;
      transform: scale(1);
    }
  }
`;

const Logo = styled.img`
  max-width: 60%;
  @media screen and (max-width: 1081px) {
    width: 100%;
    height: 100%;
  }
`;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      body_class: "body",
      menu_class: "",
      burger_class: "",
      isDesktop: false,
      scrollDirection: "",
      scroll: 0,
      navHidden: false,
    };
    this.updatePredicate = this.updatePredicate.bind(this);
    this.scrollUp = this.scrollUp.bind(this);
    this.scrollDown = this.scrollDown.bind(this);
  }
  setToggleTopMenuClass = () => {
    if (
      this.state.body_class === "" ||
      this.state.menu_class === "" ||
      this.state.burger_class === "" ||
      this.state.isDesktop === 0
    ) {
      this.setState({
        body_class: "noscroll",
        menu_class: "toggled",
        burger_class: "open"
      });
    } else {
      this.setState({
        body_class: "scroll",
        menu_class: "",
        burger_class: ""
      });
    }
  };
  componentDidMount() {
    const el = document.querySelector("nav");
    this.setState({
      top: el.offsetTop, height: el.offsetHeight,
      navHidden: true
    });
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
    window.addEventListener('scrollup', this.scrollUp);
    window.addEventListener('scrolldown', this.scrollDown);
  }
  scrollUp() {
    this.setState({ navHidden: true });
  }
  scrollDown() {
    this.setState({ navHidden: false });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth >= 1082 });
  }

  render() {
    let top_menu_class = `menu ${this.state.menu_class}`;
    let burger_class = `burger ${this.state.burger_class}`;
    let navHidden = !this.state.navHidden;
    const isDesktop = this.state.isDesktop;
    return (
      <Navbar headerHeight="144">
        <NavWrapper navHidden={navHidden} headerHeight="144" className={top_menu_class}>
          <ScrollToTopLogo
            to="banner"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            activeClass="active"
            onClick={this.setToggleTopMenuClass}
            className="page-logo"
            css="display: flex; justify-content:center; cursor: pointer;"
          >
            {isDesktop ? (
              <Logo src={require("../images/logo-header.png")} alt="logo" />
            ) : (
                <Logo src={require("../images/fix-header-logo.png")} alt="logo" />
              )}

          </ScrollToTopLogo>
          <Nav>
            <StyledLink
              to="situation"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              activeClass="active"
              onClick={this.setToggleTopMenuClass}
            >
              Situation
            </StyledLink>

            <StyledLink
              to="surface"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              activeClass="active"
              onClick={this.setToggleTopMenuClass}
            >
              Surface
            </StyledLink>

            <StyledLink
              to="reference"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              activeClass="active"
              onClick={this.setToggleTopMenuClass}
            >
              Références
            </StyledLink>

            <StyledLink
              to="simulator"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              activeClass="active"
              onClick={this.setToggleTopMenuClass}
            >
              Simulateur
            </StyledLink>

            <StyledLink
              to="contact"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              activeClass="active"
              onClick={this.setToggleTopMenuClass}
            >
              Contact
            </StyledLink>
          </Nav>
          <div className="menu-icon" onClick={this.setToggleTopMenuClass}>
            <BurgerMenu className={burger_class}>
              <span />
              <span />
              <span />
            </BurgerMenu>
          </div>
        </NavWrapper>
      </Navbar>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
