import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";

const Scroll = styled(Link)`
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%);
  border: 0;
  background: transparent;
  cursor: pointer;
  @media screen and (min-width: 768px) and (max-width: 991px) {
    bottom: 20px;
  }
  @media screen and (max-width: 767px) {
    display: none;
    position: relative;
    bottom: auto;
    left: auto;
    transform: none;
    margin: 20px auto 0;
    &.show-mobile {
      display: block;
    }
  }
`;
class ScrollBtn extends Component {
  render() {
    return (
      <Scroll
        to={this.props.btnAnchor}
        href={this.props.btnAnchor}
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
        className={this.props.mobile}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="45"
          height="45"
          viewBox="0 0 45 45"
        >
          <g id="picto-arrow-circle" transform="translate(-1097 -2040.5)">
            <g
              id="Rectangle_3"
              className="cls-1"
              data-name="Rectangle 3"
              transform="translate(1097 2040.5)"
              stroke={this.props.btnColor}
            >
              <rect width="45" height="45" className="cls-3" rx="22.5" />
              <rect
                width="43"
                height="43"
                x="1"
                y="1"
                className="cls-4"
                rx="21.5"
              />
            </g>
            <path
              id="ic_expand_more_24px"
              d="M23.65 8.59L16 16.223 8.35 8.59 6 10.94l10 10 10-10z"
              className="cls-2"
              transform="translate(1103.5 2049.91)"
              fill={this.props.btnColor}
            />
          </g>
        </svg>
      </Scroll>
    );
  }
}
export default ScrollBtn;
