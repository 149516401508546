import React from "react";
import styled from "styled-components";
import Section from "../section";
import reference from "../../images/reference.jpg";
import ScrollBtn from "../scrollbtn";
import Components from "../components";
import SbEditable from "storyblok-react";


const Anchor = styled.a`
  display: block;
  position: relative;
  visibility: hidden;
  @media screen and (max-width: 991px) {
    top: -70px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  @media screen and (max-width: 991px) {
    min-height:calc(100vh - 70px);
  }
`;
const Title = styled("h2")`
  margin: 0 auto 15px;
  max-width: 900px;
  text-align: center;
  color: #fff;
  @media screen and (max-width: 767px) {
    text-align: left;
  }

`;

const Text = styled.p`
  margin: 0 auto;
  max-width: 633px;
  text-align: center;
  line-height: 1.19;
  color: #fff;
  span {
    font-weight: bold;
  }
  @media screen and (max-width: 767px) {
    text-align: left;
  }
`;
const CardWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 10px 0 90px;
  width: 100%;
  @media screen and (max-width: 767px) {
    padding: 40px 0;
    min-height: unset;
  }
`;

const InfoText = styled(Text)`
  position: absolute;
  bottom: 120px;
  @media screen and (min-width: 768px) and (max-width: 991px) {
    bottom: 80px;
  }
  @media screen and (max-width: 767px) {
    position: relative;
    bottom: 0;
    text-align: center;
  }
`;

const Reference = props => (
  <SbEditable content={props.blok}>
    <Anchor class="anchor" id="reference"></Anchor>
    <Section imageSrc={reference} data-scroll-class="scroll" data-section-name="reference">
      <div className="container">
        <Wrapper>
          <Title
            data-aos="fade-down"
            data-aos-easing="ease-out"
            data-aos-duration="200"
            data-aos-delay="50">{props.blok.title}</Title>
          <Text
            data-aos="fade-down"
            data-aos-easing="ease-out"
            data-aos-duration="200"
            data-aos-delay="100">{props.blok.content}</Text>
          <CardWrapper>
            {props.blok.cards.map(blok =>
              React.createElement(Components[blok.component], {
                key: blok._uid,
                blok: blok
              })
            )}
          </CardWrapper>
          <InfoText
            data-aos="zoom-out"
            data-aos-easing="ease-in"
            data-aos-offset="-50"
            data-aos-delay="500">{props.blok.note}</InfoText>
          <ScrollBtn
            btnAnchor="simulator"
            btnColor="#fff"
            mobile="show-mobile"
          />
        </Wrapper>
      </div>
    </Section>
  </SbEditable>
);

export default Reference;
