import React from "react";
import styled from "styled-components";
import Section from "../section";
import CTABlue from "../cta/ctablue";
import ScrollBtn from "../scrollbtn";
import SbEditable from "storyblok-react";
import Simu from '../simulateur/simulateur.js';
import posed from 'react-pose';
import SimuImageSwitcher from './imageswitcher';

const Switch = posed.div({
  closeModal: {
    width: '100%',
    height: '100%',
    opacity: 0,
    applyAtStart: {
      display: 'none'
    },
  },
  openModal: {
    delay: 400,
    width: '100%',
    height: '100%',
    opacity: 1,
    applyAtStart: {
      display: 'flex'
    },

  }
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  @media screen and (max-width: 991px) {
    min-height:calc(100vh - 70px);
  }
  @media screen and (max-width: 767px) {
    padding: 10px 0;
  }
`;


const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  align-self: center;
  @media screen and (min-width: 992px) {
    width: 50%;
    align-self: flex-end;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    align-self: auto;
    img {
      padding-top: 20px;
    }
  }
`;

const BgSection = styled(Section)`
  background-color: #707070;
`;

const Anchor = styled.a`
  display: block;
  position: relative;
  visibility: hidden;
  @media screen and (max-width: 991px) {
    top: -70px;
  }
`
const Title = styled("h2")`
  margin: 10px auto 40px;
  color: #fff;
`;
const Text = styled.p`
  max-width: 390px;
  line-height: 1.19;
  color: #fff;
  span {
    font-weight: bold;
  }
`;
class Simulator extends React.Component {
  componentDidMount() {
  }
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      surface: 250,
    };
  }
  toggleModal() {
    this.setState({ modalVisible: !this.state.modalVisible });
  }
  surfaceChange(value) {
    this.setState({ surface: value })
  }
  render() {
    let props = this.props;
    return (
      <SbEditable content={props.blok}>
        <Anchor class="anchor" id="simulator"></Anchor>
        <BgSection data-scroll-class="scroll" data-section-name="simulateur">
          <SimuImageSwitcher value={this.state.surface} />
          <Switch pose={!this.state.modalVisible ? "openModal" : "closeModal"} >
            <div className="container">
              <Wrapper>
                <Column
                  data-aos="fade-left"
                  data-aos-easing="ease-in"
                  data-aos-delay="50">
                  <Title>{props.blok.title}</Title>
                  <Text>{props.blok.content}</Text>
                  <CTABlue buttonWidth="309px" css="margin-top: 40px;" onClick={this.toggleModal.bind(this)}>
                    {props.blok.cta_btn_name}
                  </CTABlue>
                </Column>
                <ScrollBtn btnAnchor="testimonial" btnColor="#fff" />
              </Wrapper>
            </div>
          </Switch>
          <Switch pose={this.state.modalVisible ? "openModal" : "closeModal"}>
            <div style={{ width: '100%' }}>
              <Simu action={this.surfaceChange.bind(this)} visible={this.state.modalVisible} />
            </div>
          </Switch>
        </BgSection>
      </SbEditable>
    )
  }

};

export default Simulator;
