import React from "react";
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider'
import { SliderRail, Handle, Track } from '../slider/components' // example render components - source below

const sliderStyle = {
  position: 'relative',
  width: '100%',
}

// const defaultValues = [0]
class SimuSlider extends React.Component {
  // state = {
  //   values: defaultValues.slice(),
  //   update: defaultValues.slice(),
  // }
  constructor(props) {
    super(props);
    let defaultValues = props.defaultValue ? [props.defaultValue] : [0];
    this.state = {
      values: defaultValues.slice(),
      update: defaultValues.slice(),
    };
  }

  onUpdate = update => {
    this.setState({ update })
    this.props.action(update[0]);
  }
  formatTicks = (d) => {
    return d + ' ' + this.props.unit;
  }
  onChange = values => {
    this.setState({ values })
  }
  render() {
    const {
      state: { values },
    } = this;
    let props = this.props;
    const domain = [parseFloat(props.min), parseFloat(props.max)]
    return (
      <div ref={this.props.innerRef} style={{ paddingTop: 22, paddingLeft: 20, paddingRight: 20, height: 50, width: '100%' }}>
        <Slider
          mode={1}
          step={parseFloat(props.step)}
          domain={domain}
          rootStyle={sliderStyle}
          onUpdate={this.onUpdate}
          onChange={this.onChange}
          values={values}
        >
          <Rail>
            {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
          </Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles">
                {handles.map(handle => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    domain={domain}
                    isActive={true}
                    getHandleProps={getHandleProps}
                    unit={props.unit}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
        </Slider>
      </div>
    )
  }
}

export default React.forwardRef((props, ref) => <SimuSlider innerRef={ref} {...props} />);