import React from "react";
import styled from "styled-components";
import Section from "../section";
import situation from "../../images/situation.jpg";
import ScrollBtn from "../scrollbtn";
import CTAWhite from "../cta/ctawhite";
import surface from "../../images/surface.jpg";
import SbEditable from "storyblok-react";
import ReactMarkDown from "react-markdown";
import posed from 'react-pose';
import Modal from "../modal";

const Anchor = styled.a`
  display: block;
  position: relative;
  visibility: hidden;
  @media screen and (max-width: 991px) {
    top: -70px;
  }
`

const Switch = posed.div({
  close: {
    width: '100%',
    height: '100%',
    opacity: 0,
    position: 'absolute',
    applyAtEnd: {
      display: 'none'
    },
  },
  open: {
    delay: 400,
    width: '100%',
    height: '100%',
    opacity: 1,
    position: 'absolute',
    applyAtStart: {
      display: 'flex'
    },

  }
});

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  @media screen and (max-width: 991px) {
    min-height:calc(100vh - 70px);
  }
  @media screen and (max-width: 767px) {
    padding: 10px 0 0;
  }
`;

const Column = styled.div`
  flex: 0 0 50%;
  width: 50%;
  @media screen and (max-width: 991px) and (min-width: 320px) {
    flex: 0 0 100%;
    width: 100%;
  }
`;

const Title = styled("h2")`
  margin: 0 auto 40px;

  color: #fff;


`;
const Text = styled.div`
  p {

    line-height: 1.19;
    color: #fff;
  }
`;

const BgSection = styled(Section)`
  @media screen and (max-width: 991px) {
    background-image: linear-gradient(165deg, #4299b9, #64a832);
  }
  @media screen and (max-width: 767px) {
    background-image: none;
  }
`;
const BgSurface = styled(Section)`
  @media screen and (max-width: 1199px) {
    background-position: 50%;
  }
  @media screen and (max-width: 991px) {
    background-image: linear-gradient(165deg, #4299b9, #64a832);
  }
  @media screen and (max-width: 767px) {
    background-image: none;
    padding: 0 0 60px;
  }
`;
const BgWrapper = styled.div`
  @media screen and (max-width: 767px) {
    background-image: linear-gradient(165deg, #4299b9, #64a832);
  }
`;

class Situation_Surface extends React.Component {
  componentDidMount() {
  }
  constructor(props) {
    super(props);
    this.state = { modalVisible: false };
  }
  toggleModal() {
    this.setState({ modalVisible: !this.state.modalVisible });
  }
  render() {
    let props = this.props;

    return (
    <SbEditable content={props.blok}>
      <BgWrapper>
        <Anchor class="anchor" id="situation"></Anchor>
        <BgSection imageSrc={situation} css="scroll-snap-align: start;" data-scroll-class="scroll" data-section-name="situation">
          <div className="container">
            <Wrapper>
              <Column>
                <Title
                  data-aos="fade-right"
                  data-aos-easing="ease-in"
                  data-aos-delay="0"
                  data-aos-duration="1000">{props.blok.situation_title}</Title>
                <Text
                  data-aos="fade-right"
                  data-aos-easing="ease-in"
                  data-aos-delay="50"
                  data-aos-duration="1000">
                  <ReactMarkDown source={props.blok.situation_content} />
                </Text>
                <CTAWhite
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-out"
                  data-aos-offset="0"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                  buttonWidth="309px" css="margin-top: 40px;"
                  as="a"
                  target="blank"
                  href="https://www.google.com/maps/place/ZAC+Panda,+Dumbea,+New+Caledonia/@-22.1846725,166.4352107,15z">
                  <span>Voir la localisation</span></CTAWhite>
              </Column>
              <ScrollBtn btnAnchor="surface" btnColor="#fff" />
            </Wrapper>
          </div>
          <Switch pose={this.state.modalVisible ? "open" : "close"}>
            <div style={{ width: '100%' }}>
              <Modal action={this.toggleModal.bind(this)} visible={this.state.modalVisible} />
            </div>
          </Switch>
        </BgSection>
        <Anchor class="anchor" id="surface"></Anchor>
        <BgSurface imageSrc={surface} css="scroll-snap-align: start;" data-scroll-class="scroll" data-section-name="surface">
          <div className="container">
            <Wrapper>
              <Column css={"margin-left: auto;"}>
                <Title
                  data-aos="fade-left"
                  data-aos-easing="ease-out"
                  data-aos-delay="0"
                  data-aos-duration="1000">{props.blok.surface_title}</Title>
                <Text
                  data-aos="fade-left"
                  data-aos-easing="ease-out"
                  data-aos-delay="50"
                  data-aos-duration="1000">
                  <ReactMarkDown source={props.blok.surface_content} />
                </Text>

                <CTAWhite
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-out"
                  data-aos-offset="-50"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                  buttonWidth="471px"
                  css="margin: 30px 0 20px"
                  as="a"
                  target="_blank"
                  href="/Compilation cartes des lots.pdf"
                >
                <svg xmlns="http://www.w3.org/2000/svg" height="26px" width="36px" xlink="http://www.w3.org/1999/xlink">
                  <g id="picto-carte-mobile" transform="translate(-3 -10.759)">
                  <path fill="#FFF" class="st0" d="M16.1,15.9c-3.6-3.3-8.3-5.2-13.1-5.2v16.1c4.9,0,9.6,1.8,13.1,5.2c3.6-3.3,8.3-5.2,13.1-5.2V10.8
                    C24.4,10.8,19.7,12.6,16.1,15.9z"/>
                  <path fill="#FFF" d="M6,14v10c3.7,0.5,7.1,1.9,10.1,4.1c3-2.2,6.5-3.6,10.1-4.1V14c-3,0.6-5.8,2-8.1,4.1c-0.6,0.5-1.3,0.8-2,0.8s-1.5-0.3-2-0.8
                    C11.8,16,9,14.6,6,14 M3,10.8c5.1,0,9.7,2,13.1,5.2c3.4-3.2,8.1-5.2,13.1-5.2v16.1c-5.1,0-9.7,2-13.1,5.2
                    c-3.4-3.2-8.1-5.2-13.1-5.2V10.8z"/>
                  </g>
                </svg>
                  <span>Téléchargez les cartes des lots</span>
                </CTAWhite>
                <CTAWhite
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-out"
                  data-aos-offset="-50"
                  data-aos-delay="150"
                  data-aos-duration="1000"
                  buttonWidth="471px"
                  as="a"
                  target="_blank"
                  href={props.blok.surface_sec_btn_file}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" height="32px" width="32px" xlink="http://www.w3.org/1999/xlink">
                    <g id="picto-charte-confiance-mobile">
                    <g id="Groupe_128" transform="translate(7.095 7.703)">
                      <path fill="#FFF" id="Tracé_173" d="M3,6.9c-0.3,0-0.5-0.1-0.7-0.3l-2-2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l1.2,1.3l3.9-4.2
                        c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L3.7,6.6C3.5,6.8,3.2,6.9,3,6.9L3,6.9z"/>
                    </g>
                    <path fill="#FFF" id="Tracé_174" d="M21.9,9.5l-1.7-1.7V5.1c0-1.4-1.2-2.6-2.6-2.6c0,0,0,0,0,0h-2.8l-1.7-1.7c-1-1-2.6-1-3.7,0c0,0,0,0,0,0
                      L7.8,2.5H5.1c-1.4,0-2.6,1.2-2.6,2.6v2.8L0.8,9.5c-1,1-1,2.7,0,3.7c0,0,0,0,0,0l1.7,1.7v2.8c0,1.4,1.2,2.6,2.6,2.6c0,0,0,0,0,0h2.8
                      L9.5,22c1,1,2.6,1,3.7,0c0,0,0,0,0,0l1.7-1.7h2.8c1.4,0,2.6-1.2,2.6-2.6v-2.8l1.7-1.7C23,12.2,23,10.6,21.9,9.5
                      C21.9,9.5,21.9,9.5,21.9,9.5z M18.8,12.7l-1.2,1.2v2c0,1-0.8,1.8-1.8,1.8h-2l-1.2,1.2c-0.7,0.7-1.8,0.7-2.6,0c0,0,0,0,0,0l-1.2-1.2
                      H7c-1,0-1.8-0.8-1.8-1.8v-2L4,12.7c-0.7-0.7-0.7-1.9,0-2.6c0,0,0,0,0,0l1.2-1.2v-2c0-1,0.8-1.8,1.8-1.8h2l1.2-1.2
                      c0.7-0.7,1.8-0.7,2.6,0c0,0,0,0,0,0l1.2,1.2h2c1,0,1.8,0.8,1.8,1.8v2l1.2,1.2C19.5,10.8,19.5,12,18.8,12.7z"/>
                    <path fill="#FFF" id="Tracé_175" d="M15.5,21.8l-2,1.9c-1.2,1.2-3.2,1.2-4.4,0l-2-1.9H4.9L2,28l4.7-1.2L9.3,30l1.9-4.6l3,4.6l2.6-3.6l4,0.2
                      l-3-4.8H15.5z"/>
                    </g>
                  </svg>
                  <span>Découvrez notre charte de confiance</span>
                </CTAWhite>
              </Column>
              <ScrollBtn btnAnchor="reference" btnColor="#fff" />
            </Wrapper>
          </div>
        </BgSurface>
      </BgWrapper>
    </SbEditable>
  )
}


};

export default Situation_Surface;
