import React from "react";
import SbEditable from "storyblok-react";
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider'
import { SliderRail, Handle, Track } from './slider/components' // example render components - source below

const sliderStyle = {
  position: 'relative',
  width: '100%',
}

const defaultValues = [0]
class ValueSlider extends React.Component {
  state = {
    values: defaultValues.slice(),
    update: defaultValues.slice(),
  }

  onUpdate = update => {
    this.setState({ update })
    // this.props.action(update[0]);
  }

  onChange = values => {
    this.setState({ values })
  }
  constructor(props) {
    super(props);
    console.log(props);
  }
  render() {
    const {
      state: { values },
    } = this;
    let props = this.props;
    const domain = [parseInt(props.blok.min), parseInt(props.blok.max)]
    return (
      <SbEditable content={props.blok}>
        <div style={{ height: 120, width: '100%' }}>
          <Slider
            mode={1}
            step={parseInt(props.blok.step)}
            domain={domain}
            rootStyle={sliderStyle}
            onUpdate={this.onUpdate}
            onChange={this.onChange}
            values={values}
          >
            <Rail>
              {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
            </Rail>
            <Handles>
              {({ handles, getHandleProps }) => (
                <div className="slider-handles">
                  {handles.map(handle => (
                    <Handle
                      key={handle.id}
                      handle={handle}
                      domain={domain}
                      getHandleProps={getHandleProps}
                    />
                  ))}
                </div>
              )}
            </Handles>
            <Tracks right={false}>
              {({ tracks, getTrackProps }) => (
                <div className="slider-tracks">
                  {tracks.map(({ id, source, target }) => (
                    <Track
                      key={id}
                      source={source}
                      target={target}
                      getTrackProps={getTrackProps}
                    />
                  ))}
                </div>
              )}
            </Tracks>
          </Slider>
        </div>
      </SbEditable>
    )
  }
}

export default ValueSlider