import React from "react";
import styled from "styled-components";
import SbEditable from "storyblok-react";
import ReactMarkDown from "react-markdown";

const Text = styled.div`
  color: #fff;
  p {
    margin: 0 auto 20px;
    max-width: 796px;
    text-align: center;
    font-size: 14px;
    line-height: 1.19;
  }
`;

const PName = styled.p`
  margin-bottom: 0;
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  color: #fff;
`;
const Position = styled.p`
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  opacity: 0.6;
  margin-bottom: 0;
`;
const TextWrapper = styled.div`
  padding-top: 20px;
`;

const Slide = props => (
  <SbEditable content={props.blok}>
    <div>
      <img src={props.blok.image} alt="" style={{ margin: "0 auto" }} />
      <TextWrapper>
        <Text>
          <ReactMarkDown source={props.blok.content} />
        </Text>
        <PName>{props.blok.name}</PName>
        <Position>{props.blok.position}</Position>
      </TextWrapper>
    </div>
  </SbEditable>
);

export default Slide;
