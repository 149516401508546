import styled from "styled-components";
import { Link } from "react-scroll";

const CTAGreen = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 100%;
  max-width: ${props => props.buttonwidth};
  border: 0;
  border-radius: 45px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  background: #64a832;
  transition: background-color 0.5s ease;
  cursor: pointer;
  :hover,
  :focus {
    background: #fff;
    color: #64a832;
  }
`;

export default CTAGreen;
