import styled from "styled-components";


const Section = styled("section")`
  background-image: url("${props => props.imageSrc}");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media screen and (max-width: 768px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  min-height:100vh;
  @media screen and (max-width: 991px) {
    min-height:calc(100vh - 70px);
  }
`;


export default Section;