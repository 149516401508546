import React, { Component } from "react";

import styled from "styled-components";
import Section from "../section";
import testimonial from "../../images/testimonial.jpg";
import Slider from "react-slick";
import ScrollBtn from "../scrollbtn";
import Components from "../components";
import SbEditable from "storyblok-react";
import CTAWhite from "../cta/ctawhite";

const Anchor = styled.a`
  display: block;
  position: relative;
  visibility: hidden;
  @media screen and (max-width: 991px) {
    top: -70px;
  }
`
const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 100%;
  min-height: 100vh;
  @media screen and (max-width: 991px) {
    min-height:calc(100vh - 70px);
  }
  @media screen and (max-width: 767px) {
    padding: 10px 0;
    letter-spacing: -1px;
  }
`;


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 100%;
  min-height: 100vh;
  @media screen and (max-width: 991px) {
    min-height:calc(100vh - 70px);
  }
  @media screen and (max-width: 767px) {
    padding: 10px 0;
    letter-spacing: -1px;
  }
`;
const Title = styled("h2")`
  margin: 0 auto 15px;
  max-width: 900px;
  text-align: center;
  color: #fff;
`;
const Logo = styled("img")`
  margin-bottom: 30px;
`;

const Text = styled.p`
  line-height: 1.19;
  text-align: center;
  color: #fff;
  span {
    font-weight: bold;
  }
`;
const SliderWrapper = styled.div`
  width: 100%;
  margin: 30px 0;
  @media screen and (max-width: 767px) {
    margin: 25px 0;
  }
`;

class Testimonial extends Component {
  render() {
    var settings = {
      autoplay: true,
      pauseOnHover: true,
      dots: true,
      arrows: false
    };

    let props = this.props;

    return (
      <SbEditable content={props.blok}>
        <Anchor class="anchor" id="testimonial"></Anchor>
        <Section imageSrc={testimonial} data-scroll-class="scroll" data-section-name="temoignages">
          <div className="container">
            <FlexWrapper>
              <FlexColumn>
                <Logo
                      src={require("../../images/association-entreprises-panda.jpg")}
                      alt="association d'entreprises PANDA"
                    />
                <Text>L'Association des Entreprises de PANDA regroupe les entreprises de la ZAC, favorisant la mutualisation des ressources, la solidarité et l'entraide entre ses membres.</Text>
                <CTAWhite
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-out"
                  data-aos-offset="0"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                  buttonWidth="320px" css="margin-top: 5px;" 
                  as="a"
                  target="_blank"
                  href="https://www.facebook.com/PANDA.Entreprises.nc/">

                  <span>Retrouvez-nous sur Facebook</span></CTAWhite>
              </FlexColumn>
              <Wrapper>
                <Title data-aos="fade-in-up"
                  data-aos-easing="ease-out"
                  data-aos-delay="50">{props.blok.title}</Title>
                <SliderWrapper data-aos="fade-in-up"
                  data-aos-easing="ease-out"
                  data-aos-duration="1000"
                  data-aos-delay="100">
                  <Slider {...settings} className="testimonial-slider">
                    {props.blok.slides.map(blok =>
                      React.createElement(Components[blok.component], {
                        key: blok._uid,
                        blok: blok
                      })
                    )}
                  </Slider>
                </SliderWrapper>
                <ScrollBtn btnAnchor="contact" btnColor="#fff" />
              </Wrapper>
            </FlexWrapper>
          </div>
        </Section>
      </SbEditable>
    );
  }
}

export default Testimonial;
